body {
    background-color: #5f0711;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

.MuiOutlinedInput-root fieldset {
    border-color: white;
}

.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #d5ac4e;
}

.MuiOutlinedInput-root.MuiInputBase-colorSecondary fieldset {
    border-color: #5f0711;
}

.MuiOutlinedInput-root.MuiInputBase-colorSecondary.Mui-focused fieldset {
    border-color: #5f0711;
}

.MuiButton-root.MuiButton-contained {
    background-color: white;
    color: #5f0711;
}

.MuiButton-root.MuiButton-contained:hover {
    background-color: #d5ac4e;
    color: #5f0711;
}

.MuiSpeedDial-root .MuiButtonBase-root {
    background-color: white;
    color: #5f0711;
}

.MuiSpeedDial-root .MuiButtonBase-root:hover {
    color: white;
}

.MuiSpeedDial-actions .MuiButtonBase-root:hover {
    background-color: #d5ac4e;
}